<template>
  <div>
    <div class="title"><h3>التوعيه والاتصال</h3></div>
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h3 class="brown--text">وتعتمد منهجية التوعية والإتصال علي الاتي</h3>
      </v-row>
      <v-row>
        <v-col xs="12"
          ><img
            lazy-src="@/assets/Images/loading.gif"
            src="@/assets/Images/10.jpg"
            class="rotate-vert-center"
        /></v-col>
        <v-col xs="12"
          ><img
            lazy-src="@/assets/Images/loading.gif"
            src="@/assets/Images/11.jpg"
            class="rotate-vert-center"
        /></v-col>
        <v-col xs="12"
          ><img
            lazy-src="@/assets/Images/loading.gif"
            src="@/assets/Images/12.jpg"
            class="rotate-vert-center"
        /></v-col>
      </v-row>
      <v-row>
        <h4 class="blue--text mt-5">المحافظة على الماء في دورة المياه</h4>

        <ul>
          <li>
            الحرص على احتواء المرحاض على سيفون منخفض الاستهلاك، أي بسعة غالون
            ونصف فقط مع كل استخدام
          </li>
          <li>
            عند الاستحمام، يفضّل جمع المياه الباردة التي تتدفق في البداية الى
            حين وصول المياه الساخنة، وعدم إهدارها، وتجميعها لاستعمالات أخرى كري
            المزروعات، والذي من شأنه توفير ما يزيد عن 200-300 غالون شهرياً.
          </li>
          <li>
            فحص المراحيض والتأكد من عدم وجود تسريب؛ فمثلاً يمكن وضع صبغة ملونة
            في خزان المرحاض، وفي حال ظهور اللون من دون سحب السيفون فذلك يدل على
            وجود تسريب يجب إصلاحه فوراً، مما يساعد على توفير اكثر من 400 غالون
            شهرياً.
          </li>
          <li>
            اغلاق صنبور المياه أثناء تنظيف الأسنان بالفرشاة، أو أثناء الحلاقة مع
            استخدام وعاء يحتوي على الماء لغسل أداة الحلاقة، مما يساعد على توفير
            3 غالونات يومياً
          </li>
        </ul>
      </v-row>

      <v-row
        ><h4 class="blue--text mt-5">المحافظة على الماء في الحديقة</h4></v-row
      >
      <v-row>
        <ul>
          <li>
            لاعتماد على مياه الأمطار في ري الأعشاب والاستغناء عن الرشاشات التي
            تستهلك ما يقارب الألف لتر من الماء في الساعة
          </li>
          <li>
            استخدام خزانات لتجميع مياه الأمطار والاستفادة منها في ري المزروعات،
            وتنظيف الحديقة، وغسل السيارات وغيرها.
          </li>
          <li>
            خلط التربة مع نشارة الخشب مما يساعد في الحفاظ على رطوبتها وخفض نسبة
            تبخر الماء منها بنسبة 75%.
          </li>
          <li>
            زراعة النباتات المقاومة للجفاف والتي تحتاج لكميات أقل من الماء.
          </li>
        </ul>
      </v-row>
      <v-row
        ><h4 class="blue--text mt-5">
          أهمية المحافظة على الماء من التلوث أو الاستهلاك
        </h4></v-row
      >
      <v-row
        >هنا أنّ هناك العديد من الأسباب التي تدعو للمحافظة على المياه؛</v-row
      >
      <v-row class="mb-5">
        <ul>
          <li>
            تبرز أهمية المياه على إبقاء البشر، والحيوانات، والنباتات على قيد
            الحياة.
          </li>
          <li>توفّر الماء أيضاً مواطن بيئية متخصصة للحياة البرية</li>
          <li>
            إنّ ترشيد استهلاك المياه ينعكس إيجاباً على البيئة وعلى الدخل المادي
            للفرد؛ فتوفير استهلاك الماء يوفّر الطاقة التي يتم استخدامها لتنقية
            المياه، وتسخينها، وضخّها الى المنازل، مما يساعد بالتالي على التقليل
            من انبعاثات ثاني أكسيد الكربون الى الهواء، والمحافظة على البيئة.
          </li>
          <li>
            أما من الناحية المادية الاقتصادية، فكلّما قلّ استهلاك الفرد اليومي
            للمياه، قلّت قيمة فاتورة الاستهلاك المترتبة عليه، مما يساعد بالتالي
            على توفير الأموال
          </li>
        </ul>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
</style>